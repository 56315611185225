<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Lobby TVs ({{ totalLobbyTvs }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'lobby-tvs.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add TV
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="lobby-tv-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="lobbyTvs"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalLobbyTvs"
        @click:row="editItem"
        @update:options="fetchLobbyTvs"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.building="{ item }">
          {{ item.building?.name }}
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" readonly hide-details disabled />
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ parseDate(item.updated_at) }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ parseDate(item.created_at) }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(lobbyTvs.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Lobby Tv list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import { dateFormat } from '@/utils/date'
import DATETIME_FORMAT from '../../../../utils/enums/DatetimeFormat'

export default {
  name: 'lobbyTvsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      selected: [],
      options: {},
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'updated_at',
      sortOrder: true,
      headers: [
        {
          text: 'TV Name',
          value: 'tv_name',
        },
        {
          text: 'TV Code',
          value: 'tv_code',
          sortable: false,
        },
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
        {
          text: 'Added Date',
          value: 'created_at',
        },
      ],
      actions: [],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      lobbyTvs: (state) => state.lobbyTv.list,
      listMeta: (state) => state.lobbyTv.listMeta,
      totalLobbyTvs: (state) => state.lobbyTv.totalLobbyTvs,
      paramsLobby: (state) => state.lobbyTv.queryParams,
      scrollPosition: (state) => state.lobbyTv.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getLobbyTvs: 'lobbyTv/getLobbyTvs',
    }),

    ...mapMutations({
      clearLobbyTvs: 'lobbyTv/clearLobbyTvList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'lobbyTv/setQueryParams',
      setScrollPosition: 'lobbyTv/setScrollPosition',
    }),

    fetchInitParams() {
      if (this.paramsLobby?.sort) {
        if (this.paramsLobby.sort[0].includes('-')) {
          this.defaultFilter = this.paramsLobby.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsLobby?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'tv_name'
      }

      if (this.paramsLobby?.itemsPerPage) {
        const { itemsPerPage } = this.paramsLobby
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsLobby?.search) {
        this.filter.search = this.paramsLobby.search
      }

      this.fetchLobbyTvs(null, 1, this.paramsLobby)
    },

    filterList(filterValue) {
      this.fetchLobbyTvs(null, filterValue)
    },

    // filterLobbyTv(filterValue) {
    //   this.fetchLobbyTvs(null, filterValue)
    // },

    search: debounce(function () {
      this.fetchLobbyTvs()
    }, 600),

    async fetchLobbyTvs(options, page = 1, paramsLobby = null) {
      if (this.loading) return
      this.setLoadingBuilding(true)

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalLobbyTvs
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalLobbyTvs
          : this.itemsPerPageTables,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '-tv_name',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearLobbyTvs()
      this.loading = true
      await this.getLobbyTvs(paramsLobby || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.scrollToPosition()
          this.setLoadingBuilding(true)
          this.loading = false
        })
      this.setParams(params)
    },

    editItem(item) {
      this.$router.push({
        name: 'lobby-tvs.details',
        params: { id: item.id },
      })
    },

    parseDate(date) {
      return dateFormat(date, DATETIME_FORMAT.shortDateFormat)
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
